var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Item',{staticClass:"group flex flex-col w-full",class:{
    'text-center': _vm.parentAlign === 'center',
    'text-right': _vm.parentAlign === 'right',
  },attrs:{"item":_vm.item,"parent":_vm.parent}},[_c('QuoteIcon',{staticClass:"absolute w-8 h-8 fill-current quote-icon"}),_vm._v(" "),(_vm.quoteTextItem)?_c('Content',{staticClass:"col-span-2 col-start-1 pl-12 mb-4 text-lg italic",attrs:{"item":_vm.quoteTextItem,"parent":_vm.item}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex items-center mt-auto",class:{
      'pl-12 text-left': _vm.parentAlign === 'left',
      'mx-auto text-left': _vm.parentAlign === 'center',
      'ml-auto flex-row-reverse text-right': _vm.parentAlign === 'right',
    }},[(_vm.faceItem)?_c('ImageItem',{staticClass:"w-20 h-20",attrs:{"dimensions":_vm.dimensions,"item":_vm.faceItem,"appearance":_vm.faceItemAppearance,"parent":_vm.item}}):_vm._e(),_vm._v(" "),_c('div',{class:_vm.parentAlign === 'right' ? 'mr-4' : 'ml-4'},[(_vm.nameItem)?_c('Content',{staticClass:"font-bold",attrs:{"item":_vm.nameItem,"parent":_vm.item}}):_vm._e(),_vm._v(" "),(_vm.titleItem)?_c('Content',{staticClass:"-mt-1 text-sm",attrs:{"item":_vm.titleItem,"parent":_vm.item}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }