<template>
  <Item
    class="group flex flex-col w-full"
    :item="item"
    :parent="parent"
    :class="{
      'text-center': parentAlign === 'center',
      'text-right': parentAlign === 'right',
    }"
  >
    <QuoteIcon class="absolute w-8 h-8 fill-current quote-icon" />

    <Content
      v-if="quoteTextItem"
      :item="quoteTextItem"
      :parent="item"
      class="col-span-2 col-start-1 pl-12 mb-4 text-lg italic"
    />

    <div
      class="flex items-center mt-auto"
      :class="{
        'pl-12 text-left': parentAlign === 'left',
        'mx-auto text-left': parentAlign === 'center',
        'ml-auto flex-row-reverse text-right': parentAlign === 'right',
      }"
    >
      <ImageItem
        v-if="faceItem"
        :dimensions="dimensions"
        class="w-20 h-20"
        :item="faceItem"
        :appearance="faceItemAppearance"
        :parent="item"
      />

      <div :class="parentAlign === 'right' ? 'mr-4' : 'ml-4'">
        <Content
          v-if="nameItem"
          class="font-bold"
          :item="nameItem"
          :parent="item"
        />

        <Content
          v-if="titleItem"
          class="-mt-1 text-sm"
          :item="titleItem"
          :parent="item"
        />
      </div>
    </div>
  </Item>
</template>

<script>
import itemMixin from '@/mixins/item'

export default {
  name: 'Quote',

  components: {
    QuoteIcon: () => import('~/assets/quote-1.svg?inline'),
    Content: () => import('@/components/Items/Content'),
    ImageItem: () => import('@/components/Items/ImageItem'),
    Item: () => import('@/components/Item'),
  },

  mixins: [itemMixin],

  computed: {
    faceItemAppearance() {
      return this.faceItem.props.appearance
    },

    dimensions() {
      return this.itemsConfigs.Quote.ImageItem.dimensions
    },

    faceItem() {
      return this.findChild('face')
    },

    quoteTextItem() {
      return this.findChild('quote')
    },

    nameItem() {
      return this.findChild('name')
    },

    titleItem() {
      return this.findChild('title')
    },

    parentAlign() {
      return this.parent.props.align
    },
  },
}
</script>

<style lang="postcss">
.quote-icon {
  color: var(--contrastColor);
}
</style>
